import React from 'react';
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
} from '@tabler/icons';
import {
  createStyles,
  Title,
  Text,
  Button,
  Container,
  Group,
} from '@mantine/core';

const useStyles = createStyles(theme => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  label: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: theme.spacing.xl * 1.5,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[4]
        : theme.colors.gray[2],

    [theme.fn.smallerThan('sm')]: {
      fontSize: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl * 1.5,
  },
}));

const PaymentSuccess = () => {
  const { classes } = useStyles();
  return (
    <Container className={classes.root}>
      <Title className={classes.title}>Thank you for your donation.</Title>
      <Text
        color="dimmed"
        size="md"
        align="center"
        className={classes.description}
      >
        We are on a mission to work in the flood affected area with the children
        and the basic medical facility as the the post-flooded effect stays
        there for months.
      </Text>
      <Text align="center">
        Join us on social media to get updates about our work
      </Text>
      <Group position="center">
        <Button
          leftIcon={<IconBrandFacebook />}
          variant="white"
          component="a"
          href="https://www.facebook.com/indianawazfoundation"
          target="_blank"
        >
          Facebook
        </Button>
        <Button
          leftIcon={<IconBrandTwitter />}
          variant="white"
          component="a"
          href="https://www.twitter.com/FoundationAwaz"
          target="_blank"
        >
          Twitter
        </Button>
        <Button
          leftIcon={<IconBrandInstagram />}
          variant="white"
          component="a"
          href="https://www.instagram.com/indianawazfoundation/"
          target="_blank"
        >
          Instagram
        </Button>
        <Button
          leftIcon={<IconBrandLinkedin />}
          variant="white"
          component="a"
          href="https://www.linkedin.com/company/indianawazfoundation"
          target="_blank"
        >
          LinkedIn
        </Button>
      </Group>
    </Container>
  );
};

export default PaymentSuccess;

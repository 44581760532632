import React from "react";
import {
  Grid,
  Container,
  Card,
  Image,
  Text,
  Group,
  Paper,
  Space,
} from "@mantine/core";
// import MonthlyDonation from "../components/MonthlyDonation";
// import OneTimeDonation from "../components/OneTimeDonation";
import DonationImage from "../static/img/aid-for-assam-donate-now.png";
import upiQr from "../static/img/upi-qr.jpeg";

const DonateNow = () => {
  // const [donationType, setDonationType] = useState("monthly");

  return (
    <Container size="lg" my="md">
      <Grid grow gutter="md">
        <Grid.Col md={6}>
          <Card shadow="sm" p="lg" radius="md" withBorder>
            <Card.Section>
              <Image src={DonationImage} height={160} alt="Aid for Assam" />
            </Card.Section>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={500}>100% of your donation goes to the field.</Text>
            </Group>

            <Text mb="sm">
              Every year Lakhs of people are affected by floods and a number of
              people lose their life and property. We are on a mission to work
              in the flood affected area with the children and the basic medical
              facility as the the post-flooded effect stays there for months. We
              are working to provide counseling, primary assistance, and relief
              to the affected one.
            </Text>
            <Text>
              As an independent organization, we run on the individual donors
              and in the past two years we have successfully run the campaign
              reaching out to thousands of families in the interior part of
              Assam.
            </Text>
          </Card>
        </Grid.Col>
        <Grid.Col md={6}>
          <Paper shadow="xs" p="md">
            {/* <SegmentedControl
              fullWidth
              value={donationType}
              onChange={setDonationType}
              data={[
                { label: 'Give Once', value: 'one-time' },
                { label: 'Monthly', value: 'monthly' },
              ]}
              mb="md"
            />
            {donationType === 'monthly' ? (
              <MonthlyDonation donationType={donationType} />
            ) : (
              <OneTimeDonation donationType={donationType} />
            )} */}
            <Text fw={700}>
              Please use below details to make donations through bank transfer
              and NEFT.
            </Text>
            <Text>
              Account Name:{" "}
              <Text span fw={500}>
                Indianawaz Foundation
              </Text>
            </Text>
            <Text>
              Name of Bank:{" "}
              <Text span fw={500}>
                HDFC Bank Ltd
              </Text>
            </Text>
            <Text>
              Account Number:{" "}
              <Text span fw={500}>
                50100636790535
              </Text>
            </Text>
            <Text>
              IFSC Code:{" "}
              <Text span fw={500}>
                HDFC0004678
              </Text>
            </Text>
            <Space h="md" />
            <Text fw={700}>Scan below QR code to make payment using UPI</Text>
            <Space h="sm" />
            <Image src={upiQr} width={300} alt="UPI QR code" />
          </Paper>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default DonateNow;

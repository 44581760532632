import React from 'react';
import {
  createStyles,
  Title,
  Text,
  Container,
  AspectRatio,
  Box,
} from '@mantine/core';
import Hero from '../components/Hero';
import PhotoSlider from '../components/PhotoSlider';
import OurCommunity from '../components/OurCommunity';

const useStyles = createStyles(theme => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    [theme.fn.smallerThan('sm')]: {
      fontSize: 24,
    },
  },

  description: {
    maxWidth: 600,
    margin: 'auto',

    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  textHighlight: {
    color: theme.colors[theme.primaryColor][4],
  },
}));

const Home = () => {
  const { classes } = useStyles();

  return (
    <div>
      <Hero />
      <OurCommunity />
      <Container size="md" py="xl">
        <Title order={2} className={classes.title} align="center" mt="sm">
          Help us continue to reach out to more people in{' '}
          <Text component="span" inherit className={classes.textHighlight}>
            flood affected areas of Assam
          </Text>
        </Title>

        <Text
          color="dimmed"
          className={classes.description}
          align="center"
          mt="md"
        >
          Every year through your generous help, we are able to reach out to the
          families who are affected in the flood.
        </Text>
        <AspectRatio ratio={16 / 9}>
          <iframe
            src="https://www.youtube.com/embed/1iButsmpcq0"
            title="Aid For Assam Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </AspectRatio>
      </Container>
      <Box
        sx={theme => ({
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[6]
              : theme.colors.gray[0],
          textAlign: 'center',
          padding: theme.spacing.xl,
          borderRadius: theme.radius.md,
          cursor: 'pointer',

          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[5]
                : theme.colors.gray[1],
          },
        })}
      >
        <Container size="xl">
          <PhotoSlider />
        </Container>
      </Box>
    </div>
  );
};

export default Home;

import React from 'react';
import image from '../static/img/team-image.png';
import { Image, Container, Title, Text, SimpleGrid } from '@mantine/core';
const About = () => {
  return (
    <Container my="lg" py="lg" size="lg">
      <SimpleGrid
        spacing={80}
        cols={2}
        breakpoints={[{ maxWidth: 'sm', cols: 1, spacing: 40 }]}
      >
        <div>
          <Title>About us</Title>
          <Text align="justify">
            An initiative under the Indian Awaz Foundation, Aid for Assam is a
            crowdfunded project through which the organization develops outreach
            programs to offer aid to the people of Assam at the face of
            disasters and unfavorable conditions. Indian Awaz being a
            humanitarian NGO in India, has taken this initiative in order to
            provide aid for any suffering and distressed part of the population
            of the state. Since it's inception, Aid for Assam has held relief
            providing campaigns for different parts of the state devastated and
            crippled by the Brahmaputra floods.
          </Text>
        </div>
        <Image src={image} />
      </SimpleGrid>
      <Text align="justify" my="sm">
        Under the moniker of 'Aid for Assam', Indian Awaz has made it a focal
        point of their humanitarian and philanthropic work, and has taken a
        pledge to stand by and offer a helping hand to the people of Assam in
        the face of any disaster or situation that ensues helpless suffering for
        the people.
      </Text>
      <Text align="justify" mb="sm">
        Through crowdfunding, Aid for Assam has been made successful so far due
        to the benevolent and philanthropic mindset of it's patrons; and the
        organization believes in the advent of more opportunities to further
        their projects with the help of a potent society. Through this project,
        Indian Awaz has so far conducted flood relief materials outreach program
        to many regions of the state, giving a hope to the voiceless and ignored
        population devastated by floods in the time of a pandemic-providing
        regular necessities like dry ration and also hygienic necessities like
        soaps and masks to help combat Covid-19.
      </Text>
      <Text align="justify">
        {' '}
        'Aid for Assam' will continue to work diligently and relentlessly for
        the people of Assam, standing by their side in the times of desperate
        need stemming from disasters and hazardous situations. The organization
        is open for collaborations with volunteering organizations and corporate
        willing to be benevolent patrons in providing help to the people of
        Assam.
      </Text>
    </Container>
  );
};

export default About;

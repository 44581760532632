import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import theme from './utils/Theme';
import Home from './pages/Home';
import HeaderMenu from './components/HeaderMenu';
import FooterMenu from './components/FooterMenu';
import About from './pages/About';
import DonateNow from './pages/DonateNow';
import PaymentSuccess from './pages/PaymentSuccess';
import Contact from './pages/Contact';
import BecomeVolunteer from './pages/BecomeVolunteer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import NotFound from './components/NotFound';

function App() {
  return (
    <Router>
      <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
        <HeaderMenu />
        <div
          sx={{
            display: 'flex',
            alignItems: 'space-between',
            minHeight: 100,
            backgroundColor: 'red',
            border: '10px',
          }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/donate-now" element={<DonateNow />} />
            <Route path="/become-volunteer" element={<BecomeVolunteer />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
          <FooterMenu />
        </div>
      </MantineProvider>
    </Router>
  );
}

export default App;

import React from 'react';
import { Carousel } from '@mantine/carousel';
import { Image } from '@mantine/core';
import GridImageOne from '../static/img/gallery-1.png';
import GridImageTwo from '../static/img/gallery-2.png';
import GridImageThree from '../static/img/gallery-3.png';
import GridImageSmallOne from '../static/img/gallery-sm-1.png';
import GridImageSmallTwo from '../static/img/gallery-sm-2.png';
import GridImageSmallThree from '../static/img/gallery-sm-3.png';
import GridImageSmallFour from '../static/img/gallery-sm-4.png';

const PhotoSlider = () => {
  return (
    <Carousel
      withIndicators
      height={300}
      slideSize="33.333333%"
      slideGap="md"
      breakpoints={[
        { maxWidth: 'md', slideSize: '50%' },
        { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
      ]}
      align="start"
      loop
    >
      <Carousel.Slide>
        <Image src={GridImageSmallOne} />
      </Carousel.Slide>
      <Carousel.Slide>
        <Image src={GridImageSmallTwo} />
      </Carousel.Slide>
      <Carousel.Slide>
        <Image src={GridImageSmallThree} />
      </Carousel.Slide>
      <Carousel.Slide>
        <Image src={GridImageSmallFour} />
      </Carousel.Slide>
      <Carousel.Slide>
        <Image src={GridImageOne} />
      </Carousel.Slide>
      <Carousel.Slide>
        <Image src={GridImageTwo} />
      </Carousel.Slide>
      <Carousel.Slide>
        <Image src={GridImageThree} />
      </Carousel.Slide>
    </Carousel>
  );
};

export default PhotoSlider;

import React from 'react';
import {
  createStyles,
  Text,
  Container,
  ActionIcon,
  Group,
} from '@mantine/core';
import {
  IconBrandTwitter,
  IconBrandInstagram,
  IconBrandFacebook,
  IconBrandLinkedin,
} from '@tabler/icons';
import logo from '../static/aid-for-assam-logo.png';

const useStyles = createStyles(theme => ({
  footer: {
    marginTop: 50,
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  logo: {
    maxWidth: 500,

    [theme.fn.smallerThan('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: theme.spacing.xl,
    },
  },

  description: {
    marginTop: 5,

    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
      textAlign: 'center',
    },
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  groups: {
    display: 'flex',
    flexWrap: 'wrap',

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },

  wrapper: {
    width: 200,
    [theme.fn.smallerThan('sm')]: {
      marginBottom: theme.spacing.xl,
    },
  },

  link: {
    display: 'block',
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[1]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.sm,
    paddingTop: 3,
    paddingBottom: 3,

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  title: {
    fontSize: theme.fontSizes.md,
    fontWeight: 500,
    marginBottom: theme.spacing.xs / 2,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },

  afterFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },

  social: {
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
    },
  },
}));

const data = [
  {
    title: 'About us',
    links: [
      {
        label: 'Aid for Assam',
        link: './about-us',
      },
      {
        label: 'Indian Awaz Foundation',
        link: 'https://indianawazfoundation.org',
      },
    ],
  },
  {
    title: 'Get Involved',
    links: [
      {
        label: 'Make donation',
        link: './donate-now',
      },
      {
        label: 'Become Volunteer',
        link: './become-volunteer',
      },
    ],
  },
  {
    title: 'Important Links',
    links: [
      {
        label: 'Privacy Policy',
        link: './privacy-policy',
      },
      {
        label: 'Terms & Conditions',
        link: './terms-conditions',
      },
    ],
  },
];

const FooterMenu = () => {
  const { classes } = useStyles();
  const groups = data.map(group => {
    const links = group.links.map((link, index) => (
      <Text key={index} className={classes.link} component="a" href={link.link}>
        {link.label}
      </Text>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner} size="lg">
        <div className={classes.logo}>
          <img src={logo} alt="Aid for Assam" title="Aid for Assam" />
          <Text size="xs" color="dimmed" className={classes.description}>
            An initiative under the Indian Awaz Foundation, Aid for Assam is a
            crowdfunded project through which the organization develops outreach
            programs to offer aid to the people of Assam at the face of
            disasters and unfavorable conditions.
          </Text>
        </div>
        <div className={classes.groups}>{groups}</div>
      </Container>
      <Container className={classes.afterFooter} size="lg">
        <Text color="dimmed" size="sm">
          © {new Date().getFullYear()} Indian Awaz Foundation. All rights
          reserved.
        </Text>

        <Group spacing={0} className={classes.social} position="right" noWrap>
          <ActionIcon
            component="a"
            href="https://www.facebook.com/indianawazfoundation"
            size="lg"
            target="_blank"
            title="Facebook"
          >
            <IconBrandFacebook size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            component="a"
            href="https://www.twitter.com/FoundationAwaz"
            size="lg"
            title="Twitter"
          >
            <IconBrandTwitter size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            component="a"
            href="https://www.instagram.com/indianawazfoundation/"
            size="lg"
            title="Instagram"
          >
            <IconBrandInstagram size={18} stroke={1.5} />
          </ActionIcon>
          <ActionIcon
            component="a"
            href="https://www.linkedin.com/company/indianawazfoundation"
            size="lg"
            title="LinkedIn"
          >
            <IconBrandLinkedin size={18} stroke={1.5} />
          </ActionIcon>
        </Group>
      </Container>
    </footer>
  );
};

export default FooterMenu;

import React from 'react';
import {
  Card,
  Image,
  Text,
  createStyles,
  Container,
  SimpleGrid,
  Title,
} from '@mantine/core';
import WhoWeAre from '../static/img/who-we-are.png';
import WhatWeDo from '../static/img/what-we-do.png';
import HowYouHelp from '../static/img/how-you-help.png';

const useStyles = createStyles(theme => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    [theme.fn.smallerThan('sm')]: {
      fontSize: 24,
    },
  },

  description: {
    maxWidth: 600,
    margin: 'auto',

    '&::after': {
      content: '""',
      display: 'block',
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  card: {
    position: 'relative',
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },
  cardTitle: {
    display: 'block',
    marginTop: theme.spacing.md,
    marginBottom: theme.spacing.xs / 2,
    fontWeight: 700,

    ':hover': {
      color: theme.colors[theme.primaryColor][4],
    },
  },
  highlight: {
    color: theme.colors[theme.primaryColor][4],
  },
}));

const OurCommunity = () => {
  const { classes } = useStyles();
  return (
    <Container size="lg" py="xl" style={{ textAlign: 'center' }}>
      <Title order={2} className={classes.title} align="center" mt="sm">
        Our small steps bringing{' '}
        <Text component="span" inherit className={classes.highlight}>
          smile to people
        </Text>
      </Title>

      <Text
        color="dimmed"
        className={classes.description}
        align="center"
        my="md"
      >
        Since 2020, we're working in Assam floods
      </Text>
      <SimpleGrid
        cols={3}
        spacing="lg"
        breakpoints={[
          { maxWidth: 980, cols: 3, spacing: 'md' },
          { maxWidth: 755, cols: 2, spacing: 'sm' },
          { maxWidth: 600, cols: 1, spacing: 'sm' },
        ]}
      >
        <Card withBorder radius="md" className={classes.card}>
          <Card.Section>
            <Image src={WhoWeAre} height={250} />
          </Card.Section>

          <Text
            className={classes.cardTitle}
            weight={500}
            component="a"
            href="/about-us"
          >
            Who we are
          </Text>

          <Text size="sm" color="dimmed" lineClamp={4}>
            Aid for Assam is a crowdfunded project...
          </Text>
        </Card>
        <Card withBorder radius="md" className={classes.card}>
          <Card.Section>
            <Image src={WhatWeDo} height={250} />
          </Card.Section>

          <Text
            className={classes.cardTitle}
            weight={500}
            component="a"
            href="about-us"
          >
            What we do
          </Text>
          <Text size="sm" color="dimmed" lineClamp={4}>
            Focus Areas: Child Health & Education and Women Empowerment.
          </Text>
        </Card>
        <Card withBorder radius="md" className={classes.card}>
          <Card.Section>
            <Image src={HowYouHelp} height={250} />
          </Card.Section>

          <Text
            className={classes.cardTitle}
            weight={500}
            component="a"
            href="donate-now"
          >
            How you help
          </Text>

          <Text size="sm" color="dimmed" lineClamp={4}>
            Join our team as a volunteer or donate us
          </Text>
        </Card>
      </SimpleGrid>
    </Container>
  );
};

export default OurCommunity;
